import { styles } from "./shared";
import { H2 } from "./shared";
import { H3 } from "./shared";
import { linkStyles } from "./shared";
import { serializeRoute } from "../route";
import { RouteKind } from "../route";

export function KoerperspracheVorstellungsgespraech() {
  return (
    <>
      <section style={styles.content.section}>
        <p style={styles.content.paragraph}>
          Im Vorstellungsgespräch entscheiden oft die ersten 90 Sekunden über
          Sympathie oder Antipathie. Dabei kommunizierst du nicht nur mit
          Worten, sondern vor allem durch deine Körpersprache. Experten
          schätzen, dass über 55% unserer Kommunikation nonverbal stattfindet –
          ein Faktor, der im Bewerbungsprozess häufig unterschätzt wird. Während
          ein{" "}
          <a
            href={serializeRoute({
              kind: RouteKind.Blog,
              blogRoute: { kind: "lebenslauf-guide" },
            })}
            style={linkStyles}
          >
            überzeugender Lebenslauf
          </a>{" "}
          und ein{" "}
          <a
            href={serializeRoute({
              kind: RouteKind.Blog,
              blogRoute: { kind: "anschreiben-guide" },
            })}
            style={linkStyles}
          >
            maßgeschneidertes Anschreiben
          </a>{" "}
          dich zum Gespräch bringen, ist es deine Körpersprache, die maßgeblich
          darüber entscheidet, ob du einen bleibenden positiven Eindruck
          hinterlässt.
        </p>

        <p style={styles.content.paragraph}>
          Besonders herausfordernd wird die Kontrolle der Körpersprache in
          Zeiten, in denen immer mehr Vorstellungsgespräche im Homeoffice per
          Videocall stattfinden. Die digitale Barriere schafft neue Regeln und
          erfordert besondere Aufmerksamkeit für die nonverbalen Signale, die du
          sendest und empfängst. Dieser Artikel hilft dir, deine Körpersprache
          sowohl im persönlichen Gespräch als auch im virtuellen Raum bewusst zu
          steuern, um kompetent, selbstbewusst und authentisch zu wirken.
        </p>

        <div style={styles.content.note}>
          Kurz & Knapp: Deine Körpersprache beeinflusst maßgeblich, wie du im
          Vorstellungsgespräch wahrgenommen wirst. Aufrechte Haltung, gemäßigter
          Blickkontakt und kontrollierte Gestik signalisieren Kompetenz und
          Selbstbewusstsein. Im Homeoffice-Interview sind zusätzlich optimale
          Kameraposition, angemessene Beleuchtung und ein aufgeräumter
          Hintergrund entscheidend. Das Wichtigste: Übe vorab und bleibe trotz
          bewusster Körpersprache authentisch.
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Der erste Eindruck: Entscheidend in Präsenz und im Homeoffice</H2>
        <p style={styles.content.paragraph}>
          Bereits bevor du ein Wort gesprochen hast, bildet sich dein Gegenüber
          ein erstes Urteil. Diese initiale Einschätzung ist erstaunlich
          beständig und schwer zu korrigieren. Umso wichtiger ist es, vom ersten
          Moment an die richtigen nonverbalen Signale zu senden.
        </p>

        <H3>Die Ankunft beim persönlichen Gespräch</H3>
        <p style={styles.content.paragraph}>
          Dein Auftritt beginnt nicht erst im Besprechungszimmer, sondern
          bereits beim Betreten des Unternehmens. Bedenke: Auch der Empfang oder
          andere Mitarbeitende könnten um ihre Einschätzung gebeten werden.
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Aufrechter Gang:</strong> Betritt das Gebäude mit geradem
            Rücken und festen, aber nicht stampfenden Schritten. Dies
            signalisiert Selbstbewusstsein und Zielstrebigkeit.
          </li>
          <li style={styles.content.listItem}>
            <strong>Offene Körperhaltung:</strong> Vermeide verschränkte Arme
            oder eine zusammengesunkene Haltung, selbst wenn du nervös bist oder
            warten musst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Der Handschlag:</strong> Ein mittelfester, trockener
            Händedruck mit Blickkontakt vermittelt Selbstsicherheit. In
            Post-Corona-Zeiten warte jedoch ab, ob dein Gegenüber die Hand
            anbietet oder eine alternative Begrüßungsform wählt.
          </li>
        </ul>

        <H3>Der virtuelle erste Eindruck im Homeoffice</H3>
        <p style={styles.content.paragraph}>
          Bei Videointerviews beginnt der erste Eindruck bereits vor dem
          eigentlichen Gesprächsstart – nämlich mit deinem digitalen
          Erscheinungsbild und deiner Umgebung.
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Pünktliches Einwählen:</strong> Sei mindestens 5 Minuten vor
            Gesprächsbeginn in der Videokonferenz anwesend. So vermeidest du
            Hektik und signalisierst Zuverlässigkeit.
          </li>
          <li style={styles.content.listItem}>
            <strong>Kameraposition:</strong> Die Kamera sollte auf Augenhöhe
            positioniert sein, nicht von unten oder zu weit oben filmen. Bei
            Laptops bedeutet dies oft, diese auf Bücher oder einen Ständer zu
            stellen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Professioneller Hintergrund:</strong> Dein sichtbarer
            Homeoffice-Bereich sollte aufgeräumt und nicht ablenkend sein.
            Virtuelle Hintergründe können eine Alternative sein, wirken aber oft
            unprofessionell, wenn sie unruhig sind oder Teile deines Körpers
            "abschneiden".
          </li>
          <li style={styles.content.listItem}>
            <strong>Angemessene Beleuchtung:</strong> Achte auf ausreichendes,
            gleichmäßiges Licht, das dein Gesicht gut ausleuchtet. Vermeide
            starkes Gegenlicht durch Fenster hinter dir.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Tipp für Homeoffice-Interviews: Teste dein technisches Setup
            mindestens einen Tag vorab. Nichts untergräbt deinen professionellen
            Eindruck mehr als Probleme mit Kamera, Mikrofon oder
            Internetverbindung, die durch sorgfältige Vorbereitung vermeidbar
            gewesen wären.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Körperhaltung: Das Fundament deiner nonverbalen Kommunikation</H2>
        <p style={styles.content.paragraph}>
          Deine Körperhaltung ist ein starker Indikator für dein
          Selbstbewusstsein und deine Einstellung zum Gespräch. Sie beeinflusst
          nicht nur, wie andere dich wahrnehmen, sondern auch, wie du dich
          selbst fühlst und verhältst.
        </p>

        <H3>Im persönlichen Gespräch</H3>
        <p style={styles.content.paragraph}>
          Die ideale Sitzposition vermittelt gleichzeitig Professionalität und
          eine angemessene Entspanntheit:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Aufrechte Position:</strong> Sitze gerade, aber nicht steif,
            mit dem unteren Rücken an der Stuhllehne und leicht nach vorne
            geneigt. Diese Haltung signalisiert Aufmerksamkeit und Engagement.
          </li>
          <li style={styles.content.listItem}>
            <strong>Beinposition:</strong> Für Männer ist eine offene Position
            mit beiden Füßen auf dem Boden ideal. Frauen können die Beine
            übereinanderschlagen, sollten aber darauf achten, nicht ständig die
            Position zu wechseln. Vermeide das nervöse Wippen mit den Füßen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Armhaltung:</strong> Lege deine Arme locker auf dem Tisch ab
            oder nutze sie für natürliche Gesten. Vermeide es, die Arme vor der
            Brust zu verschränken, was als defensive oder verschlossene Haltung
            interpretiert werden kann.
          </li>
        </ul>

        <H3>Im Homeoffice-Interview</H3>
        <p style={styles.content.paragraph}>
          Bei Videogesprächen ist dein sichtbarer Bereich eingeschränkt, was
          besondere Aufmerksamkeit für die Oberkörperhaltung erfordert:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Optimaler Bildausschnitt:</strong> Idealerweise ist dein
            Oberkörper vom Brustbereich aufwärts sichtbar. Zu nahe Aufnahmen
            deines Gesichts können unangenehm wirken, während zu weit entfernte
            Einstellungen Distanz schaffen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Dynamischer Sitzplatz:</strong> Nutze einen Stuhl, der dir
            erlaubt, aufrecht zu sitzen. Vermeidest du ein Sofa oder Bett, auch
            wenn diese bequemer erscheinen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Bewegungsfreiheit:</strong> Positioniere dich so, dass du
            natürliche Gesten ausführen kannst, ohne aus dem Bildausschnitt zu
            verschwinden oder zu nah an die Kamera zu kommen.
          </li>
        </ul>

        <p style={styles.content.paragraph}>
          Beachte: Studien der Sozialpsychologie zeigen, dass eine aufrechte,
          offene Körperhaltung nicht nur positiver wahrgenommen wird, sondern
          auch dein eigenes Selbstbewusstsein stärkt. Das Konzept des „Power
          Posing" besagt, dass bereits zwei Minuten in einer selbstbewussten
          Haltung vor dem Gespräch deinen Testosteronspiegel erhöhen und den
          Stresshormonspiegel senken können – eine einfache Technik, um deine
          Nervosität zu reduzieren.
        </p>
      </section>

      <section style={styles.content.section}>
        <H2>Blickkontakt und Mimik: Dein Gesicht spricht Bände</H2>
        <p style={styles.content.paragraph}>
          Dein Gesicht ist das ausdrucksstärkste Element deiner Körpersprache.
          Insbesondere deine Augen und dein Lächeln beeinflussen maßgeblich, wie
          sympathisch und vertrauenswürdig du wirkst.
        </p>

        <H3>Blickkontakt richtig dosieren</H3>
        <p style={styles.content.paragraph}>
          Blickkontakt ist ein Balanceakt: Zu wenig signalisiert Unsicherheit
          oder mangelndes Interesse, zu viel kann als dominierend oder gar
          unangenehm empfunden werden.
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Die 70/30-Regel:</strong> Halte während etwa 70% der
            Gesprächszeit Blickkontakt und schweife in den restlichen 30% dezent
            ab. Dies wird als angenehm und natürlich empfunden.
          </li>
          <li style={styles.content.listItem}>
            <strong>Bei mehreren Gesprächspartnern:</strong> Verteile deinen
            Blickkontakt gleichmäßig auf alle Anwesenden, auch wenn eine Person
            die meisten Fragen stellt. Alle Entscheidungsträger sollten sich
            einbezogen fühlen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Beim Nachdenken:</strong> Es ist völlig in Ordnung, beim
            Überlegen den Blick kurz abzuwenden. Schau dann aber gezielt wieder
            auf, wenn du antwortest.
          </li>
        </ul>

        <H3>Blickkontakt im Homeoffice-Gespräch</H3>
        <p style={styles.content.paragraph}>
          Die Besonderheit bei Videocalls: Der natürliche Impuls ist, auf den
          Bildschirm zu schauen, wo du dein Gegenüber siehst. Für maximalen
          "Blickkontakt" solltest du jedoch direkt in die Kamera blicken.
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Die Kamera-Bildschirm-Balance:</strong> Positioniere das
            Fenster mit deinen Gesprächspartnern möglichst nahe an deiner
            Kamera, idealerweise direkt darunter. So wirkt dein Blick
            natürlicher.
          </li>
          <li style={styles.content.listItem}>
            <strong>Bewusste Kamerablicke:</strong> Schaue besonders zu Beginn
            deiner Antworten und bei wichtigen Aussagen direkt in die Kamera, um
            Verbindung herzustellen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Notizen strategisch platzieren:</strong> Lege eventuelle
            Notizen nah an der Kamera ab, damit dein Blick nicht offensichtlich
            abschweift, wenn du sie nutzt.
          </li>
        </ul>

        <H3>Die Kraft des authentischen Lächelns</H3>
        <p style={styles.content.paragraph}>
          Ein Lächeln kann Barrieren abbauen und Sympathie erzeugen – aber nur,
          wenn es echt wirkt:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Das Duchenne-Lächeln:</strong> Ein echtes Lächeln erkennt
            man daran, dass es die Augen mit einbezieht (die sogenannten
            "Lachfältchen"). Übe vor dem Spiegel, wie sich ein authentisches von
            einem aufgesetzten Lächeln unterscheidet.
          </li>
          <li style={styles.content.listItem}>
            <strong>Dosierung:</strong> Lächle zu Beginn und Ende des Gesprächs
            sowie an passenden Stellen zwischendurch. Ein permanentes Lächeln
            wirkt unnatürlich und kann deine Seriosität untergraben.
          </li>
          <li style={styles.content.listItem}>
            <strong>Mimische Kongruenz:</strong> Deine Mimik sollte zum Inhalt
            deiner Aussagen passen. Bei ernsteren Themen ist ein nachdenklicher
            Gesichtsausdruck angemessener als ein Lächeln.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Für Homeoffice-Interviews: Überlege, ob du mit oder ohne Brille
            teilnimmst, falls du Brillenträger bist. Brillen können Blendeffekte
            verursachen, die den Blickkontakt erschweren. Falls du sie trägst,
            stelle deine Beleuchtung so ein, dass keine störenden Reflexionen
            entstehen.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Gestik: Mit Händen überzeugend kommunizieren</H2>
        <p style={styles.content.paragraph}>
          Deine Hände sind mächtige Kommunikationswerkzeuge. Bewusst eingesetzte
          Gesten können deine Botschaften verstärken, während unbewusste
          Bewegungen Nervosität oder Unsicherheit verraten können.
        </p>

        <H3>Wirkungsvolle Gesten im persönlichen Gespräch</H3>
        <p style={styles.content.paragraph}>
          Die richtige Handbewegung zur richtigen Zeit kann deiner Aussage
          Nachdruck verleihen und deine Überzeugungskraft steigern:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Offene Handflächen:</strong> Sichtbare Handflächen
            signalisieren Offenheit und Ehrlichkeit. Nutze sie besonders, wenn
            du von deinen Motivationen oder Werten sprichst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Präzisionsgesten:</strong> Mit Daumen und Zeigefinger
            präzise Punkte anzudeuten vermittelt Genauigkeit und Fachwissen –
            ideal für technische oder detaillierte Erklärungen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Zählgesten:</strong> Beim Aufzählen mehrerer Punkte kannst
            du dies mit den Fingern unterstreichen, was die Struktur deiner
            Antwort verdeutlicht und dem Zuhörer hilft, zu folgen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Ruhepositionen:</strong> In Gesprächspausen können die Hände
            locker auf dem Tisch liegen, leicht gefaltet oder mit den
            Fingerspitzen aneinandergelegt (sogenannte "Kathedrale" – vermittelt
            Kompetenz und Selbstsicherheit).
          </li>
        </ul>

        <H3>Gestik im Homeoffice-Videointerview</H3>
        <p style={styles.content.paragraph}>
          Im virtuellen Raum wirken Gesten anders und müssen bewusster
          eingesetzt werden:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Bildrahmen beachten:</strong> Achte darauf, dass deine
            Gesten im sichtbaren Bereich bleiben. Zu weite Armbewegungen
            verschwinden aus dem Bild und wirken abrupt.
          </li>
          <li style={styles.content.listItem}>
            <strong>Bewusstere Bewegungen:</strong> Durch die Kameraübertragung
            gehen subtile Gesten oft verloren. Führe deine Handbewegungen daher
            etwas bewusster und größer aus, aber ohne zu übertreiben.
          </li>
          <li style={styles.content.listItem}>
            <strong>Verminderte Geschwindigkeit:</strong> Aufgrund möglicher
            Verzögerungen in der Übertragung sollten Gesten etwas langsamer
            ausgeführt werden als im persönlichen Gespräch.
          </li>
          <li style={styles.content.listItem}>
            <strong>Gesten zur Kamera hin:</strong> Führe wichtige Gesten leicht
            zur Kamera gerichtet aus, damit sie besser sichtbar sind.
          </li>
        </ul>

        <H3>Nervöse Gesten vermeiden</H3>
        <p style={styles.content.paragraph}>
          Unbewusste, repetitive Handbewegungen können von deinen Inhalten
          ablenken und Unsicherheit signalisieren:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Hand-zu-Gesicht-Gesten:</strong> Das Berühren des Gesichts,
            besonders von Nase, Mund oder Ohren, wird oft als Zeichen von
            Unsicherheit oder Unehrlichkeit interpretiert.
          </li>
          <li style={styles.content.listItem}>
            <strong>Selbstberührungen:</strong> Ständiges Zurechtrücken der
            Kleidung, Spielen mit Schmuck oder Haaren verrät Nervosität.
          </li>
          <li style={styles.content.listItem}>
            <strong>Versteckte Hände:</strong> Hände unter dem Tisch oder hinter
            dem Rücken zu verbergen kann als Zeichen von mangelndem
            Selbstvertrauen gedeutet werden.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Homeoffice-Tipp: Platziere auf deinem Schreibtisch einen kleinen,
            unauffälligen Stressball oder ein anderes diskretes Objekt außerhalb
            des Kamerablickfelds. In besonders nervösen Momenten kannst du
            diesen kurz drücken, um Anspannung abzubauen, ohne dass es sichtbar
            ist.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Stimme und Sprechmodus: Der akustische Eindruck</H2>
        <p style={styles.content.paragraph}>
          Obwohl streng genommen nicht Teil der Körpersprache, ist deine Stimme
          eng mit deiner nonverbalen Kommunikation verbunden. Wie du sprichst,
          kann deine Kompetenz und dein Selbstbewusstsein ebenso unterstreichen
          oder untergraben wie deine Körperhaltung.
        </p>

        <H3>Stimmführung im persönlichen Gespräch</H3>
        <p style={styles.content.paragraph}>
          Eine kontrollierte, angenehme Stimme vermittelt Autorität und
          Gelassenheit:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Tempo:</strong> Sprich in mittlerem Tempo – zu schnelles
            Sprechen wirkt nervös, zu langsames kann langweilen. Variiere das
            Tempo leicht, um wichtige Punkte zu betonen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Lautstärke:</strong> Wähle eine angenehme, gut hörbare
            Lautstärke. Zu leises Sprechen wirkt unsicher, zu lautes kann
            aggressiv wirken.
          </li>
          <li style={styles.content.listItem}>
            <strong>Tonhöhe:</strong> Eine tiefere Stimme wird tendenziell als
            kompetenter wahrgenommen. Versuche besonders bei Nervosität, deine
            Stimme nicht zu hoch werden zu lassen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Pausen:</strong> Setze bewusst kurze Pausen ein, um wichtige
            Aussagen zu betonen. Schweigen kann ein mächtiges
            Kommunikationsmittel sein.
          </li>
        </ul>

        <H3>Optimale Tonqualität im Homeoffice-Interview</H3>
        <p style={styles.content.paragraph}>
          In Videointerviews ist die Audioqualität entscheidend für deinen
          professionellen Eindruck:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Mikrofon-Setup:</strong> Investiere in ein gutes Headset
            oder externes Mikrofon für klaren Klang. Die eingebauten Mikrofone
            von Laptops sind oft unzureichend.
          </li>
          <li style={styles.content.listItem}>
            <strong>Raumakustik:</strong> Vermeide hallende Räume und
            Hintergrundgeräusche. Ein Raum mit Teppich, Vorhängen oder anderen
            schallabsorbierenden Elementen ist ideal.
          </li>
          <li style={styles.content.listItem}>
            <strong>Deutlichere Artikulation:</strong> Sprich im Videocall
            bewusst etwas deutlicher und langsamer als im persönlichen Gespräch,
            um Verständnisprobleme durch technische Limitationen auszugleichen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Stummschalten:</strong> Schalte dein Mikrofon stumm, wenn du
            längere Zeit zuhörst, um störende Hintergrundgeräusche zu vermeiden.
            Achte aber darauf, es rechtzeitig wieder zu aktivieren.
          </li>
        </ul>

        <H3>Mit der Stimme Kompetenz vermitteln</H3>
        <p style={styles.content.paragraph}>
          Deine Sprachgewohnheiten können deine wahrgenommene Autorität
          signifikant beeinflussen:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Füllwörter reduzieren:</strong> Vermeide übermäßige
            Verwendung von "ähm", "quasi" oder "sozusagen". Diese Füllwörter
            untergraben deine Überzeugungskraft.
          </li>
          <li style={styles.content.listItem}>
            <strong>Fragende Intonation vermeiden:</strong> Achte darauf, deine
            Stimme am Satzende nicht anzuheben, wenn du eine Aussage machst.
            Diese Angewohnheit lässt dich unsicher wirken.
          </li>
          <li style={styles.content.listItem}>
            <strong>Aktive Formulierungen:</strong> Vermeide abschwächende
            Formulierungen wie "ich könnte", "vielleicht" oder "ich würde
            sagen". Ersetze sie durch selbstbewusste Aussagen: "ich kann", "ich
            werde", "ich bin überzeugt".
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Homeoffice-Aufnahmetest: Mache vor dem Vorstellungsgespräch eine
            kurze Probeaufnahme mit dem gleichen technischen Setup, das du auch
            im Interview verwenden wirst. So kannst du Ton- und Bildqualität
            überprüfen und bei Bedarf Anpassungen vornehmen.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Spiegeln und Rapport: Subtile Verbindung aufbauen</H2>
        <p style={styles.content.paragraph}>
          Eine der wirkungsvollsten Techniken der nonverbalen Kommunikation ist
          das sogenannte "Spiegeln" oder "Matching" – das dezente Anpassen
          deiner Körpersprache an die deines Gesprächspartners. Diese Technik
          schafft unterbewusst Verbindung und Sympathie.
        </p>

        <H3>Die Kunst des dezenten Spiegelns</H3>
        <p style={styles.content.paragraph}>
          Beim Spiegeln geht es nicht um offensichtliche Imitation, sondern um
          subtile Anpassung:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Zeitversetztes Anpassen:</strong> Übernimm bestimmte Gesten,
            Körperhaltungen oder Sprechmuster mit einigen Sekunden Verzögerung,
            damit es nicht als direkte Nachahmung auffällt.
          </li>
          <li style={styles.content.listItem}>
            <strong>Selektives Spiegeln:</strong> Wähle bewusst aus, welche
            Elemente du spiegelst. Übernimm positive, offene Gesten, aber keine
            Anzeichen von Nervosität oder negativen Emotionen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Sprachliches Matching:</strong> Passe dich auch verbal an,
            indem du ähnliche Formulierungen oder Fachbegriffe verwendest, die
            dein Gegenüber benutzt.
          </li>
        </ul>

        <H3>Spiegeln im virtuellen Raum</H3>
        <p style={styles.content.paragraph}>
          Im Homeoffice-Interview kann diese Technik ebenfalls angewendet
          werden, erfordert aber besondere Aufmerksamkeit:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Sichtbarer Rahmen:</strong> Konzentriere dich auf die
            Elemente, die in deinem Bildausschnitt erkennbar sind – vor allem
            Gesichtsausdruck, Kopfhaltung und Oberkörperbewegungen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Energieniveau anpassen:</strong> Beobachte das Sprechtempo
            und die Dynamik deines Gegenübers und passe dich moderat an – ohne
            dabei unnatürlich zu wirken.
          </li>
          <li style={styles.content.listItem}>
            <strong>Aktives Zuhören betonen:</strong> Da viele subtile Signale
            verloren gehen können, verstärke deine Zustimmungssignale durch
            bewusstes Nicken oder verbale Bestätigungen wie "Ich verstehe" oder
            "Das ist ein interessanter Punkt".
          </li>
        </ul>

        <H3>Die Balance finden</H3>
        <p style={styles.content.paragraph}>
          Bei aller Anpassung ist es wichtig, authentisch zu bleiben:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Natürliche Integration:</strong> Spiegeln sollte so subtil
            und natürlich wirken, dass es vom Gegenüber nicht bewusst
            wahrgenommen wird.
          </li>
          <li style={styles.content.listItem}>
            <strong>Eigene Persönlichkeit bewahren:</strong> Passe dich an, ohne
            deine eigene Authentizität aufzugeben. Ein völlig verändertes
            Verhalten wirkt unglaubwürdig.
          </li>
          <li style={styles.content.listItem}>
            <strong>Bewusste Abweichung:</strong> In manchen Situationen kann es
            sinnvoll sein, bewusst nicht zu spiegeln – etwa wenn der
            Gesprächspartner eine sehr verschlossene oder negative Körpersprache
            zeigt.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Das Spiegeln ist besonders im Homeoffice-Kontext hilfreich, wo die
            räumliche Distanz zusätzliche Barrieren schafft. Durch bewusstes
            Anpassen deiner sichtbaren Körpersprache kannst du die digitale
            Kluft überbrücken und eine persönlichere Verbindung herstellen.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Häufige Fehler vermeiden: Körpersprachliche Fallstricke</H2>
        <p style={styles.content.paragraph}>
          Selbst bei guter Vorbereitung schleichen sich oft unbewusste
          Verhaltensweisen ein, die deinen kompetenten Eindruck untergraben
          können. Hier sind die häufigsten Fehler und wie du sie vermeidest.
        </p>

        <H3>Universelle Fehler im Präsenz- und Homeoffice-Gespräch</H3>
        <p style={styles.content.paragraph}>
          Diese Verhaltensweisen solltest du unabhängig vom Gesprächsformat
          vermeiden:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Übermäßiges Fidgeting:</strong> Nervöses Spielen mit
            Gegenständen, Fingern oder Haaren lenkt ab und signalisiert
            Unsicherheit. Wenn du zu nervösen Bewegungen neigst, halte deine
            Hände bewusst in einer ruhigen Position.
          </li>
          <li style={styles.content.listItem}>
            <strong>Uhr-Checking:</strong> Wiederholtes Blicken auf die Uhr oder
            das Smartphone erweckt den Eindruck von Desinteresse. Stelle dein
            Telefon auf lautlos und lege es außer Sichtweite.
          </li>
          <li style={styles.content.listItem}>
            <strong>Übermäßiges Gestikulieren:</strong> Während Gesten wichtig
            sind, können zu ausladende oder hektische Handbewegungen ablenken
            und unprofessionell wirken.
          </li>
          <li style={styles.content.listItem}>
            <strong>Das "Pokerface":</strong> Eine völlig ausdruckslose Mimik
            erschwert es deinem Gegenüber, eine Verbindung aufzubauen. Zeige
            angemessene emotionale Reaktionen.
          </li>
        </ul>

        <H3>Spezifische Fallstricke im persönlichen Gespräch</H3>
        <p style={styles.content.paragraph}>
          Im direkten Gespräch solltest du besonders auf diese Aspekte achten:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Distanzlosigkeit:</strong> Das Unterschreiten der
            angemessenen persönlichen Distanz (in Deutschland etwa 60-80 cm)
            kann als übergriffig empfunden werden.
          </li>
          <li style={styles.content.listItem}>
            <strong>Verschlossene Körperhaltung:</strong> Verschränkte Arme,
            übereinandergeschlagene Beine oder eine vom Gesprächspartner
            abgewandte Haltung signalisieren Abwehr oder Desinteresse.
          </li>
          <li style={styles.content.listItem}>
            <strong>Haltungswechsel:</strong> Häufiges Verändern der
            Sitzposition kann als Unruhe oder Ungeduld interpretiert werden.
            Finde eine komfortable Grundhaltung.
          </li>
          <li style={styles.content.listItem}>
            <strong>Dominanzgesten:</strong> Auf den Tisch trommeln, in den
            persönlichen Raum des anderen eindringen oder von oben herab
            sprechen wirkt aggressiv und unangenehm.
          </li>
        </ul>

        <H3>Typische Homeoffice-Fehler</H3>
        <p style={styles.content.paragraph}>
          In Videointerviews aus dem Homeoffice treten spezifische Probleme auf:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Kameravermeidung:</strong> Ständig neben die Kamera statt
            hinein zu schauen vermittelt den Eindruck, dass du dem Gespräch
            ausweichst oder unaufmerksam bist.
          </li>
          <li style={styles.content.listItem}>
            <strong>Unvorteilhafter Bildausschnitt:</strong> Eine Kamera, die
            dich von unten filmt, ergibt einen dominanten, potenziell
            bedrohlichen Eindruck. Von oben wirkst du eher unterwürfig.
          </li>
          <li style={styles.content.listItem}>
            <strong>Ablenkung durch Hintergrund:</strong> Ein unaufgeräumter
            oder sehr persönlicher Hintergrund kann vom Gespräch ablenken oder
            einen unprofessionellen Eindruck vermitteln.
          </li>
          <li style={styles.content.listItem}>
            <strong>Multitasking:</strong> Das Lesen von E-Mails oder anderen
            Dokumenten während des Gesprächs ist oft deutlicher erkennbar als du
            denkst – die Augenbewegungen verraten dich.
          </li>
          <li style={styles.content.listItem}>
            <strong>Unpassende Kleidung:</strong> Der Fehler, nur den sichtbaren
            Oberkörper professionell zu kleiden, kann peinlich werden, wenn du
            unerwartet aufstehen musst.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Homeoffice-Tipp: Bedenke, dass in Videointerviews oft mehr von
            deiner privaten Umgebung sichtbar ist als du glaubst. Prüfe vor dem
            Gespräch genau, was alles im Kameraausschnitt zu sehen ist, und
            räume persönliche Gegenstände aus dem Blickfeld, die du nicht teilen
            möchtest. Ein neutraler, aufgeräumter Hintergrund lässt dich am
            professionellsten erscheinen.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Vorbereitung und Training: Körpersprache bewusst steuern</H2>
        <p style={styles.content.paragraph}>
          Selbstbewusste, kompetente Körpersprache ist selten angeboren – sie
          kann und sollte trainiert werden. Mit der richtigen Vorbereitung wirst
          du nicht nur überzeugender wirken, sondern dich auch sicherer fühlen.
        </p>

        <H3>Bewusstsein entwickeln</H3>
        <p style={styles.content.paragraph}>
          Der erste Schritt zur Verbesserung deiner Körpersprache ist, dir
          deiner aktuellen Gewohnheiten bewusst zu werden:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Videoanalyse:</strong> Nimm dich bei einem simulierten
            Interview auf und analysiere deine Körpersprache kritisch. Achte
            besonders auf unbewusste Gesten, Haltung und Blickkontakt.
          </li>
          <li style={styles.content.listItem}>
            <strong>Feedback einholen:</strong> Bitte Freunde oder Familie um
            ehrliches Feedback zu deinem Auftreten in Gesprächen. Andere nehmen
            oft Muster wahr, die uns selbst nicht auffallen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Professionelles Coaching:</strong> Bei besonders wichtigen
            Vorstellungsgesprächen kann ein Coaching durch einen
            Kommunikationsexperten wertvoll sein.
          </li>
        </ul>

        <H3>Praktisches Training für das persönliche Gespräch</H3>
        <p style={styles.content.paragraph}>
          Diese Übungen helfen dir, eine kompetente Körpersprache zu entwickeln:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Spiegel-Training:</strong> Übe vor dem Spiegel verschiedene
            Gesten, Haltungen und Gesichtsausdrücke, um ein Gefühl dafür zu
            bekommen, wie sie wirken.
          </li>
          <li style={styles.content.listItem}>
            <strong>Mock-Interviews:</strong> Bitte jemanden, ein
            Übungsinterview mit dir durchzuführen. Idealerweise in einer
            ähnlichen Umgebung wie das tatsächliche Gespräch.
          </li>
          <li style={styles.content.listItem}>
            <strong>Power Posing:</strong> Praktiziere vor dem Gespräch für 2
            Minuten eine selbstbewusste Körperhaltung (breite Schultern, Hände
            in die Hüften oder über dem Kopf). Studien zeigen, dass dies
            Stresshormone reduzieren und Selbstbewusstsein steigern kann.
          </li>
          <li style={styles.content.listItem}>
            <strong>Atemübungen:</strong> Tiefes, bewusstes Atmen beruhigt nicht
            nur, sondern verbessert auch deine Stimme und Haltung. Praktiziere
            vor dem Gespräch 5-10 tiefe Atemzüge.
          </li>
        </ul>

        <H3>Homeoffice-spezifisches Training</H3>
        <p style={styles.content.paragraph}>
          Für Videointerviews aus dem Homeoffice sind zusätzliche Vorbereitungen
          sinnvoll:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Technik-Generalprobe:</strong> Teste dein gesamtes Setup
            mindestens einen Tag vor dem Interview – inklusive Kameraposition,
            Beleuchtung, Mikrofon und Hintergrund.
          </li>
          <li style={styles.content.listItem}>
            <strong>Raumgestaltung:</strong> Richte deinen Homeoffice-Bereich
            professionell ein. Achte auf neutralen Hintergrund, gute Beleuchtung
            und Ruhe.
          </li>
          <li style={styles.content.listItem}>
            <strong>Kamerablick trainieren:</strong> Übe das Sprechen mit Blick
            in die Kamera. Klebe bei Bedarf einen kleinen Aufkleber neben die
            Kamera als Erinnerung.
          </li>
          <li style={styles.content.listItem}>
            <strong>Testaufnahme machen:</strong> Zeichne dich in deinem
            Interview-Setup auf und prüfe, wie du wirkst. Achte besonders auf
            die Positionierung im Bild, deine Haltung und die Beleuchtung.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Vor dem Homeoffice-Interview: Informiere alle Mitbewohner über dein
            wichtiges Gespräch, um Störungen zu vermeiden. Wenn möglich, hänge
            ein "Bitte nicht stören"-Schild an deine Tür und schalte alle nicht
            benötigten Geräte aus oder in den Stummmodus. Bereite auch ein Glas
            Wasser vor, um Stimmprobleme zu vermeiden.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Kulturelle Unterschiede beachten</H2>
        <p style={styles.content.paragraph}>
          Körpersprache wird stark von kulturellen Normen beeinflusst. Was in
          einem kulturellen Kontext als selbstbewusst gilt, kann in einem
          anderen als unangemessen empfunden werden. Bei internationalen
          Unternehmen oder multikulturellen Teams ist es wichtig, diese
          Unterschiede zu berücksichtigen.
        </p>

        <H3>Deutsche Geschäftskultur verstehen</H3>
        <p style={styles.content.paragraph}>
          In Deutschland gelten bestimmte kulturelle Normen, die sich auch in
          der erwarteten Körpersprache widerspiegeln:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Distanz:</strong> Deutsche bevorzugen typischerweise einen
            etwas größeren persönlichen Raum (ca. 60-80 cm) als Menschen aus
            südeuropäischen oder lateinamerikanischen Kulturen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Formelle Begrüßung:</strong> Ein fester Händedruck mit
            direktem Blickkontakt ist traditionell wichtig, wobei seit der
            COVID-19-Pandemie alternative Begrüßungsformen häufiger akzeptiert
            werden.
          </li>
          <li style={styles.content.listItem}>
            <strong>Zurückhaltende Gestik:</strong> Ausladende Gesten werden in
            Deutschland oft als übertrieben empfunden. Eine etwas
            zurückhaltendere, aber präzise Gestik wird positiver wahrgenommen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Direkter Blickkontakt:</strong> In der deutschen
            Geschäftskultur gilt direkter Blickkontakt als Zeichen von
            Ehrlichkeit und Interesse, sollte aber nicht zum Starren werden.
          </li>
        </ul>

        <H3>Internationale Perspektiven berücksichtigen</H3>
        <p style={styles.content.paragraph}>
          Bei Bewerbungen bei internationalen Unternehmen oder ausländischen
          Arbeitgebern solltest du folgende Unterschiede beachten:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Angelsächsischer Raum:</strong> In den USA wird oft mehr
            Enthusiasmus und Expressivität erwartet als in Deutschland. Ein
            breiteres Lächeln und positivere Ausdrucksweise sind üblich.
          </li>
          <li style={styles.content.listItem}>
            <strong>Skandinavien:</strong> Ähnlich wie in Deutschland, aber oft
            mit noch mehr Wert auf Gleichberechtigung und weniger Hierarchie.
            Eine zu selbstbewusste Darstellung kann als arrogant empfunden
            werden.
          </li>
          <li style={styles.content.listItem}>
            <strong>Asiatischer Raum:</strong> In vielen asiatischen Kulturen
            gilt zurückhaltendere Körpersprache als respektvoll. Direkter
            Blickkontakt sollte dosierter eingesetzt werden, besonders gegenüber
            hierarchisch höhergestellten Personen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Südeuropa:</strong> In Ländern wie Italien oder Spanien ist
            expressivere Gestik und geringere körperliche Distanz üblich und
            wird positiv als Engagement und Leidenschaft wahrgenommen.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Recherchiere vor dem Gespräch die kulturellen Hintergründe des
            Unternehmens und der Gesprächspartner. Bei internationalen Konzernen
            kann es hilfreich sein, zu wissen, ob sie eher von der Kultur des
            Hauptsitzes oder des lokalen Standorts geprägt sind. Im Zweifelsfall
            ist eine leicht formal-zurückhaltende Körpersprache meist die
            sicherere Wahl.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Authentisch bleiben trotz bewusster Körpersprache</H2>
        <p style={styles.content.paragraph}>
          Bei allem Wissen um effektive Körpersprache solltest du niemals deine
          Authentizität opfern. Personalverantwortliche haben ein feines Gespür
          für aufgesetztes Verhalten. Das Ziel ist nicht, eine Rolle zu spielen,
          sondern die beste Version deiner selbst zu präsentieren.
        </p>

        <H3>Balance zwischen Authentizität und Optimierung</H3>
        <p style={styles.content.paragraph}>
          So findest du die richtige Balance:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Natürliche Stärkung:</strong> Arbeite mit deinen natürlichen
            Verhaltensweisen und verstärke positive Aspekte, statt dich komplett
            zu verstellen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Graduelle Anpassung:</strong> Verändere deine Körpersprache
            schrittweise über Zeit, statt plötzlich ein völlig neues Verhalten
            anzunehmen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Persönlicher Stil:</strong> Finde deinen eigenen Stil
            selbstbewusster Körpersprache, der zu deiner Persönlichkeit passt.
            Es gibt nicht die eine "richtige" Art, kompetent zu wirken.
          </li>
          <li style={styles.content.listItem}>
            <strong>Fokus auf Inhalt:</strong> Konzentriere dich hauptsächlich
            auf deine fachlichen Antworten. Eine optimierte Körpersprache soll
            deine Inhalte unterstützen, nicht ersetzen.
          </li>
        </ul>

        <H3>Umgang mit Nervosität</H3>
        <p style={styles.content.paragraph}>
          Fast jeder ist vor Vorstellungsgesprächen nervös. Daran ist nichts
          falsch, und ein gewisses Maß an Nervosität kann sogar positiv sein:
        </p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Akzeptiere Nervosität:</strong> Statt zu versuchen, deine
            Nervosität vollständig zu verbergen, akzeptiere sie als normalen
            Teil des Prozesses.
          </li>
          <li style={styles.content.listItem}>
            <strong>Kleines Eingeständnis:</strong> Ein kurzes "Ich bin etwas
            aufgeregt, weil mir diese Position sehr wichtig ist" kann
            sympathisch wirken und Druck abbauen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Tiefe Atmung:</strong> Bewusstes, tiefes Atmen hilft,
            körperliche Anzeichen von Nervosität zu reduzieren und den Geist zu
            beruhigen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Mentale Vorbereitung:</strong> Visualisiere vor dem Gespräch
            einen erfolgreichen Verlauf. Diese Technik wird von Spitzensportlern
            genutzt und kann auch dir helfen.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Denk daran: Das Vorstellungsgespräch ist keine Einbahnstraße. Du
            präsentierst nicht nur dich selbst, sondern prüfst auch, ob das
            Unternehmen und die Position zu dir passen. Diese Perspektive kann
            helfen, übermäßigen Druck zu reduzieren und authentischer
            aufzutreten.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Fazit: Deine Körpersprache als Erfolgsfaktor</H2>
        <p style={styles.content.paragraph}>
          Deine Körpersprache ist ein mächtiges Instrument, das maßgeblich über
          Erfolg oder Misserfolg im Vorstellungsgespräch entscheiden kann. Sie
          vermittelt dein Selbstvertrauen, deine Kompetenz und deine Motivation
          – oft unbewusst und lange bevor du deine fachlichen Qualifikationen
          unter Beweis stellen kannst.
        </p>

        <p style={styles.content.paragraph}>
          Besonders in der heutigen Arbeitswelt mit zunehmenden Homeoffice- und
          hybriden Arbeitsmodellen ist die bewusste Steuerung deiner
          Körpersprache sowohl im persönlichen als auch im virtuellen Raum eine
          entscheidende Kompetenz. Die gute Nachricht: Mit Wissen, Übung und
          Vorbereitung kannst du deine nonverbale Kommunikation gezielt
          verbessern.
        </p>

        <p style={styles.content.paragraph}>Die wichtigsten Takeaways:</p>

        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Erste Eindrücke zählen:</strong> Achte auf einen
            professionellen Auftritt von der ersten Sekunde an – sei es durch
            eine aufrechte Haltung im persönlichen Gespräch oder durch optimale
            Kameraposition und Beleuchtung im Homeoffice.
          </li>
          <li style={styles.content.listItem}>
            <strong>Selbstbewusste Grundhaltung:</strong> Eine aufrechte
            Körperhaltung, angemessener Blickkontakt und kontrollierte,
            zielgerichtete Gesten vermitteln Kompetenz und Selbstsicherheit.
          </li>
          <li style={styles.content.listItem}>
            <strong>Format-spezifische Anpassung:</strong> Die Regeln für
            Körpersprache unterscheiden sich zwischen persönlichen und
            Homeoffice-Gesprächen. Bereite dich gezielt auf das jeweilige Format
            vor.
          </li>
          <li style={styles.content.listItem}>
            <strong>Übung macht den Meister:</strong> Trainiere deine
            Körpersprache durch Videoanalyse, Feedback und praktische Übungen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Authentizität bewahren:</strong> Optimiere deine
            Körpersprache, ohne dich zu verstellen. Die beste Strategie ist, die
            selbstbewussteste Version deiner selbst zu sein.
          </li>
        </ul>

        <p style={styles.content.paragraph}>
          Denk daran: Eine überzeugende Körpersprache ergänzt deine fachlichen
          Qualifikationen – sie ersetzt sie nicht. In Kombination mit einem
          soliden{" "}
          <a
            href={serializeRoute({
              kind: RouteKind.Blog,
              blogRoute: { kind: "lebenslauf-guide" },
            })}
            style={linkStyles}
          >
            Lebenslauf
          </a>{" "}
          und einem auf die Stelle zugeschnittenen{" "}
          <a
            href={serializeRoute({
              kind: RouteKind.Blog,
              blogRoute: { kind: "anschreiben-guide" },
            })}
            style={linkStyles}
          >
            Anschreiben
          </a>{" "}
          bildet sie jedoch das Fundament für einen überzeugenden
          Gesamteindruck, der dir die Türen zu deinem Wunschjob öffnen kann.
        </p>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Der{" "}
            <a style={linkStyles} href="https://bewerbungshelfer.net">
              Bewerbungshelfer
            </a>{" "}
            unterstützt dich bei der Erstellung überzeugender
            Bewerbungsunterlagen, die optimal auf deine Qualifikationen und die
            Stellenanforderungen abgestimmt sind. Mit diesem soliden Fundament
            kannst du dich voll auf deine Körpersprache und die inhaltliche
            Vorbereitung deines Vorstellungsgesprächs konzentrieren – ob im Büro
            des Arbeitgebers oder aus deinem Homeoffice.
          </p>
        </div>
      </section>
    </>
  );
}
