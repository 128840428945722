import { styles } from "./shared";
import { H2 } from "./shared";
import { H3 } from "./shared";
import { linkStyles } from "./shared";
import { serializeRoute } from "../route";
import { RouteKind } from "../route";

export function Initiativbewerbung() {
  return (
    <>
      <section style={styles.content.section}>
        <p style={styles.content.paragraph}>
          In einem dynamischen Arbeitsmarkt kann eine gut durchdachte
          Initiativbewerbung deine Karrierechancen erheblich verbessern. Gerade
          wenn es um attraktive Positionen mit Homeoffice-Option geht, lohnt es
          sich, nicht nur auf ausgeschriebene Stellen zu warten. Denn viele
          spannende Jobs werden nie öffentlich ausgeschrieben – sie gehören zum
          sogenannten "verdeckten Arbeitsmarkt", der nach Expertenschätzungen
          bis zu 70% aller verfügbaren Stellen umfasst.
        </p>

        <p style={styles.content.paragraph}>
          Eine Initiativbewerbung zeigt Eigeninitiative und Motivation – zwei
          Eigenschaften, die besonders im Homeoffice geschätzt werden. Doch wann
          lohnt sich dieser Schritt wirklich, und wie gestaltest du eine
          Initiativbewerbung so, dass sie nicht im Papierkorb, sondern auf dem
          Schreibtisch der Entscheider landet?
        </p>

        <div style={styles.content.note}>
          Kurz & Knapp: Eine Initiativbewerbung kann dir Zugang zum verdeckten
          Arbeitsmarkt verschaffen, der nicht über öffentliche Stellenanzeigen
          zugänglich ist. Besonders in Bereichen mit hoher Homeoffice-Affinität
          wie IT, Marketing oder Beratung kann sie sehr erfolgreich sein. Der
          Schlüssel zum Erfolg liegt in gründlicher Recherche, einem
          personalisierten Anschreiben und der Darstellung deines konkreten
          Mehrwerts – auch für remote arbeitende Teams.
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Was ist eine Initiativbewerbung und wann lohnt sie sich?</H2>
        <p style={styles.content.paragraph}>
          Bei einer Initiativbewerbung bewirbst du dich aus eigenem Antrieb bei
          einem Unternehmen – ohne dass eine konkrete Stelle ausgeschrieben ist.
          Du klopfst sozusagen unaufgefordert an und präsentierst, was du zu
          bieten hast. Anders als bei einer Reaktion auf eine Stellenanzeige
          musst du hier nicht nur darlegen, warum du geeignet bist, sondern auch
          überzeugen, dass es die Position, die du anstrebst, überhaupt geben
          sollte.
        </p>

        <H3>
          In diesen Situationen ist eine Initiativbewerbung besonders sinnvoll:
        </H3>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Starkes Interesse an einem spezifischen Unternehmen</strong>
            : Wenn du unbedingt für eine bestimmte Firma arbeiten möchtest,
            signalisiert eine Initiativbewerbung echtes Engagement.
          </li>
          <li style={styles.content.listItem}>
            <strong>Mangel an passenden Stellenanzeigen</strong>: In deinem
            Spezialgebiet oder Wunschort werden selten Stellen ausgeschrieben.
          </li>
          <li style={styles.content.listItem}>
            <strong>Berufsfelder mit projektbasierter Arbeit</strong>: Branchen
            wie IT, Marketing, Beratung oder Design arbeiten oft mit temporären
            Ressourcen und flexiblen Teams.
          </li>
          <li style={styles.content.listItem}>
            <strong>Homeoffice-affine Tätigkeiten</strong>: Viele Unternehmen
            sind offener für remote arbeitende Mitarbeiter, schreiben aber nicht
            explizit Homeoffice-Stellen aus.
          </li>
          <li style={styles.content.listItem}>
            <strong>Wachsende Unternehmen</strong>: Firmen in der
            Expansionsphase haben oft Bedarf, bevor sie aktiv ausschreiben.
          </li>
        </ul>

        <H3>Statistiken zur Initiativbewerbung</H3>
        <p style={styles.content.paragraph}>
          Laut verschiedenen Studien werden zwischen 30% und 70% aller offenen
          Stellen nie öffentlich ausgeschrieben. Diese Zahlen variieren je nach
          Branche und Hierarchieebene – je höher die Position, desto
          wahrscheinlicher wird sie über Netzwerke oder direkten Kontakt
          besetzt. Etwa 20-30% der Initiativbewerbungen führen zu einem
          Vorstellungsgespräch, was im Vergleich zu regulären Bewerbungen ein
          sehr guter Wert ist.
        </p>
        <p style={styles.content.paragraph}>
          Besonders erfolgreich sind Initiativbewerbungen oft bei
          mittelständischen Unternehmen, die keine großen Personalabteilungen
          haben und flexibler auf gute Kandidaten reagieren können.
        </p>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Vorsicht bei sehr großen Konzernen: Hier laufen Bewerbungsprozesse
            oft standardisiert über Karriereportale. Eine Initiativbewerbung per
            E-Mail kann in solchen Strukturen leicht untergehen. Informiere dich
            vorab, ob das Unternehmen Initiativbewerbungen ausdrücklich
            willkommen heißt.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Gründliche Recherche als Fundament deines Erfolgs</H2>
        <p style={styles.content.paragraph}>
          Der entscheidende Unterschied zwischen einer erfolgreichen
          Initiativbewerbung und einer, die unbeachtet bleibt, liegt in der
          Vorbereitung. Da du nicht auf eine konkrete Stellenausschreibung
          reagierst, musst du selbst herausfinden, was das Unternehmen braucht
          und wie du diese Lücke füllen kannst.
        </p>

        <H3>Unternehmensrecherche: Mehr als nur die Website</H3>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Aktuelle Entwicklungen</strong>: Verfolge
            Unternehmensnachrichten, Pressemitteilungen und Geschäftsberichte.
            Expansion, neue Produkte oder Umstrukturierungen können auf
            Personalbedarf hindeuten.
          </li>
          <li style={styles.content.listItem}>
            <strong>Social Media</strong>: LinkedIn, XING und
            Unternehmensprofile auf anderen Plattformen geben Einblicke in die
            Kultur und aktuelle Projekte.
          </li>
          <li style={styles.content.listItem}>
            <strong>Mitarbeiterprofile</strong>: Analysiere die Profile von
            Mitarbeitern in ähnlichen Positionen. Welche Qualifikationen haben
            sie? Welche Aufgaben übernehmen sie?
          </li>
          <li style={styles.content.listItem}>
            <strong>Kundenreferenzen und Projekte</strong>: Sie zeigen, woran
            das Unternehmen aktuell arbeitet und wo möglicherweise Unterstützung
            benötigt wird.
          </li>
          <li style={styles.content.listItem}>
            <strong>Homeoffice-Politik</strong>: Recherchiere, wie das
            Unternehmen zu flexiblen Arbeitsmodellen steht. Erwähnen Mitarbeiter
            in Bewertungen oder Posts die Möglichkeit zum Homeoffice?
          </li>
        </ul>

        <H3>Die richtigen Ansprechpartner identifizieren</H3>
        <p style={styles.content.paragraph}>
          Eine Initiativbewerbung an die allgemeine Personalabteilung zu
          schicken, ist selten erfolgversprechend. Stattdessen solltest du
          versuchen, die Person zu identifizieren, die tatsächlich
          Einstellungsentscheidungen für deinen Bereich trifft:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Abteilungsleiter oder Teamleads</strong>: Sie kennen den
            tatsächlichen Bedarf ihres Teams am besten.
          </li>
          <li style={styles.content.listItem}>
            <strong>LinkedIn/XING recherchieren</strong>: Suche nach
            Führungskräften der relevanten Abteilung.
          </li>
          <li style={styles.content.listItem}>
            <strong>Unternehmenswebsite</strong>: Manchmal werden
            Ansprechpartner für bestimmte Bereiche genannt.
          </li>
          <li style={styles.content.listItem}>
            <strong>Direkter Anruf</strong>: Ein freundlicher Anruf beim Empfang
            kann dir helfen, den richtigen Namen zu erfahren.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Besonders wichtig für Homeoffice-Positionen: Recherchiere, welche
            Teams bereits mit Remote-Mitarbeitern arbeiten. Diese Abteilungen
            haben bereits Erfahrung mit virtueller Zusammenarbeit und sind
            wahrscheinlich offener für deine Initiativbewerbung mit
            Homeoffice-Wunsch.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>
          Überzeugende Bewerbungsunterlagen für deine Initiativbewerbung
          erstellen
        </H2>
        <p style={styles.content.paragraph}>
          Bei einer Initiativbewerbung müssen deine Unterlagen besonders
          überzeugen, da du nicht gegen andere Bewerber auf eine ausgeschriebene
          Stelle antrittst, sondern gegen den Status quo: "Wir brauchen gerade
          niemanden." Deine Aufgabe ist es, zu zeigen, dass die Einstellung
          einer Person mit deinen Qualifikationen ein Gewinn für das Unternehmen
          wäre.
        </p>

        <H3>Das Anschreiben: Dein wichtigster Türöffner</H3>
        <p style={styles.content.paragraph}>
          Das Anschreiben einer Initiativbewerbung unterscheidet sich deutlich
          von dem einer regulären Bewerbung. Es sollte folgende Elemente
          enthalten:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Persönliche Ansprache</strong>: Sprich deinen recherchierten
            Ansprechpartner direkt und mit Namen an.
          </li>
          <li style={styles.content.listItem}>
            <strong>Überzeugender Einstieg</strong>: Beginne nicht mit
            Standardfloskeln, sondern zeige sofort, dass du das Unternehmen
            kennst und verstehst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Klare Position</strong>: Benenne konkret, für welchen
            Bereich du dich bewirbst. "Eine Position in Ihrem Marketingteam" ist
            zu vage, "Social Media Manager mit Fokus auf B2B-Content" dagegen
            spezifisch.
          </li>
          <li style={styles.content.listItem}>
            <strong>Deinen Mehrwert herausstellen</strong>: Zeige auf, welches
            spezifische Problem du lösen oder welchen Beitrag du leisten kannst:
            "Mit meiner Erfahrung in der Automatisierung von Reporting-Prozessen
            könnte ich Ihrem Team helfen, den monatlichen Berichtsaufwand um bis
            zu 40% zu reduzieren."
          </li>
          <li style={styles.content.listItem}>
            <strong>Homeoffice als Vorteil positionieren</strong>: Wenn du
            remote arbeiten möchtest, stelle dar, warum dies ein Vorteil ist:
            "Als vollständig remote arbeitender Entwickler kann ich Ihr Team
            verstärken, ohne dass zusätzliche Bürofläche benötigt wird."
          </li>
        </ul>

        <p style={styles.content.paragraph}>
          Halte das Anschreiben kurz und prägnant – eine Seite sollte
          ausreichen. Die Entscheider haben wenig Zeit, und du musst schnell
          überzeugen. Mehr Tipps zur optimalen Gestaltung findest du in unserem{" "}
          <a
            href={serializeRoute({
              kind: RouteKind.Blog,
              blogRoute: { kind: "anschreiben-guide" },
            })}
            style={linkStyles}
          >
            Anschreiben-Guide
          </a>
          .
        </p>

        <H3>Der Lebenslauf: Passgenau für die angestrebte Position</H3>
        <p style={styles.content.paragraph}>
          Auch wenn du dich initiativ bewirbst, solltest du deinen Lebenslauf
          auf die angestrebte Position zuschneiden:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Relevante Erfahrungen hervorheben</strong>: Betone die
            Aspekte deiner bisherigen Tätigkeiten, die für die angestrebte
            Position wertvoll sind.
          </li>
          <li style={styles.content.listItem}>
            <strong>Erfolge quantifizieren</strong>: Konkrete Zahlen und
            messbare Erfolge machen deinen Mehrwert greifbar.
          </li>
          <li style={styles.content.listItem}>
            <strong>Homeoffice-Kompetenzen betonen</strong>: Hebe Fähigkeiten
            hervor, die besonders im Remote-Kontext wichtig sind:
            Selbstorganisation, digitale Kommunikation, Erfahrung mit
            Kollaborationstools.
          </li>
          <li style={styles.content.listItem}>
            <strong>Lücken erklären</strong>: Bei einer Initiativbewerbung
            werden Unstimmigkeiten im Lebenslauf besonders kritisch betrachtet.
          </li>
        </ul>
        <p style={styles.content.paragraph}>
          Detaillierte Anleitungen zur optimalen Gestaltung deines Lebenslaufs
          findest du in unserem{" "}
          <a
            href={serializeRoute({
              kind: RouteKind.Blog,
              blogRoute: { kind: "lebenslauf-guide" },
            })}
            style={linkStyles}
          >
            Lebenslauf-Guide
          </a>
          .
        </p>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Der{" "}
            <a style={linkStyles} href="https://bewerbungshelfer.net">
              Bewerbungshelfer
            </a>{" "}
            kann dir helfen, eine maßgeschneiderte Initiativbewerbung zu
            erstellen. Die KI analysiert deine Erfahrungen und hilft dir, sie
            optimal auf das Unternehmen abzustimmen – auch für Positionen mit
            Homeoffice-Option.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Initiativbewerbung speziell für Homeoffice-Positionen</H2>
        <p style={styles.content.paragraph}>
          Die Arbeitswelt hat sich gewandelt, und viele Unternehmen sind offener
          für remote arbeitende Mitarbeiter. Dies eröffnet neue Chancen für
          Initiativbewerbungen, da du nicht mehr auf den lokalen Arbeitsmarkt
          beschränkt bist. Allerdings müssen Bewerbungen für
          Homeoffice-Positionen besondere Aspekte berücksichtigen.
        </p>

        <H3>So überzeugst du als potenzieller Remote-Mitarbeiter</H3>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Selbstorganisation nachweisen</strong>: Beschreibe konkrete
            Situationen, in denen du eigenverantwortlich und strukturiert
            gearbeitet hast.
          </li>
          <li style={styles.content.listItem}>
            <strong>Kommunikationsfähigkeiten betonen</strong>: Im Homeoffice
            ist klare und proaktive Kommunikation entscheidend. Zeige, dass du
            diese Fähigkeit beherrschst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Technische Voraussetzungen erwähnen</strong>: Ein
            professionelles Home-Office-Setup, stabile Internetverbindung und
            Erfahrung mit Kollaborationstools sind wichtige Pluspunkte.
          </li>
          <li style={styles.content.listItem}>
            <strong>Frühere Remote-Erfahrung hervorheben</strong>: Hast du
            bereits im Homeoffice gearbeitet? Auch wenn es nur teilweise oder
            während der Pandemie war – betone diese Erfahrung.
          </li>
          <li style={styles.content.listItem}>
            <strong>Zeitliche Flexibilität ansprechen</strong>: Wenn du flexibel
            bist, was Arbeitszeiten oder gelegentliche Bürobesuche angeht,
            erwähne dies als zusätzlichen Vorteil.
          </li>
        </ul>

        <H3>Bedenken bezüglich Homeoffice proaktiv ansprechen</H3>
        <p style={styles.content.paragraph}>
          Viele Arbeitgeber haben noch Vorbehalte gegenüber Remote-Arbeit.
          Adressiere diese Bedenken proaktiv:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Integration ins Team</strong>: Zeige, wie du dich auch aus
            der Ferne ins Team einbringen wirst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Produktivitätsnachweis</strong>: Erkläre, wie du deine
            Arbeitsergebnisse messbar machst und Fortschritte kommunizierst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Work-Life-Balance</strong>: Betone deine Fähigkeit,
            Berufliches und Privates auch im Homeoffice zu trennen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Bereitschaft zu Kompromissen</strong>: Biete an, für
            wichtige Meetings oder Einarbeitungsphasen ins Büro zu kommen.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Statistik-Tipp: Erwähne in deiner Bewerbung aktuelle Studien zur
            Produktivität im Homeoffice. Laut verschiedenen Untersuchungen
            arbeiten Remote-Mitarbeiter oft produktiver und sind zufriedener,
            was zu geringerer Fluktuation führt. Solche Fakten können skeptische
            Entscheider überzeugen.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Die perfekte Initiativbewerbung absenden und nachfassen</H2>
        <p style={styles.content.paragraph}>
          Der richtige Zeitpunkt und die Art der Übermittlung deiner
          Initiativbewerbung können entscheidend für den Erfolg sein. Auch das
          Nachfassen – wenn es richtig gemacht wird – kann deine Chancen
          deutlich erhöhen.
        </p>

        <H3>Optimaler Zeitpunkt für deine Initiativbewerbung</H3>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Branchenspezifische Zyklen beachten</strong>: In vielen
            Branchen gibt es typische Einstellungszeiten, oft zu Jahresbeginn
            oder nach Abschluss des Geschäftsjahres.
          </li>
          <li style={styles.content.listItem}>
            <strong>Nach positiven Unternehmensnachrichten</strong>: Expansion,
            neue Projekte oder gute Quartalszahlen können auf Personalbedarf
            hindeuten.
          </li>
          <li style={styles.content.listItem}>
            <strong>Montag bis Mittwoch</strong>: Sende deine Bewerbung
            bevorzugt Anfang der Woche, wenn die Entscheider mehr Zeit und
            Aufmerksamkeit haben.
          </li>
          <li style={styles.content.listItem}>
            <strong>Ferienzeiten meiden</strong>: In Urlaubszeiten werden
            Initiativbewerbungen oft übersehen oder verschoben.
          </li>
        </ul>

        <H3>Übermittlungswege und ihre Vor- und Nachteile</H3>
        <p style={styles.content.paragraph}>
          Es gibt verschiedene Wege, eine Initiativbewerbung einzureichen. Jeder
          hat seine eigenen Vor- und Nachteile:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>E-Mail</strong>: Der Standardweg – schnell, direkt und
            einfach nachzuverfolgen. Achte auf eine prägnante Betreffzeile wie
            "Initiativbewerbung: Social Media Manager mit 5 Jahren
            B2B-Erfahrung".
          </li>
          <li style={styles.content.listItem}>
            <strong>Karriereportal</strong>: Viele größere Unternehmen
            akzeptieren Initiativbewerbungen über ihr Portal – hier solltest du
            dich an die Vorgaben halten.
          </li>
          <li style={styles.content.listItem}>
            <strong>LinkedIn/XING</strong>: Eine direkte Nachricht an den
            Entscheider kann in manchen Branchen ein guter Einstieg sein,
            besonders wenn du deine vollständigen Unterlagen als Follow-up
            anbietest.
          </li>
          <li style={styles.content.listItem}>
            <strong>Postalisch</strong>: In traditionelleren Branchen kann eine
            hochwertig ausgedruckte Bewerbungsmappe noch immer Eindruck machen –
            allerdings mit dem Nachteil der schwierigeren Nachverfolgung.
          </li>
        </ul>

        <H3>Richtig nachfassen: Der entscheidende Schritt</H3>
        <p style={styles.content.paragraph}>
          Viele erfolgreiche Initiativbewerbungen werden erst durch gezieltes
          Nachfassen zum Erfolg:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Timing</strong>: Warte etwa 7-10 Tage nach Absenden deiner
            Bewerbung.
          </li>
          <li style={styles.content.listItem}>
            <strong>Medium</strong>: Ein freundlicher Anruf kann wirksamer sein
            als eine E-Mail, da er schwerer zu ignorieren ist.
          </li>
          <li style={styles.content.listItem}>
            <strong>Vorbereitung</strong>: Formuliere vorab, was du sagen
            möchtest, und sei bereit für ein spontanes Mini-Interview.
          </li>
          <li style={styles.content.listItem}>
            <strong>Positive Einstellung</strong>: Frage nicht, ob deine
            Bewerbung angekommen ist, sondern zeige weiterhin Interesse und
            biete an, offene Fragen zu beantworten.
          </li>
        </ul>

        <p style={styles.content.paragraph}>
          Selbst wenn es aktuell keine passende Position gibt, kann ein gutes
          Nachfassgespräch zu einem Informationsinterview oder einem Eintrag in
          den Talentpool führen. Viele Unternehmen merken sich interessante
          Initiativbewerber für zukünftige Vakanzen.
        </p>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Tipp für Homeoffice-Bewerbungen: Beim Nachfassen kannst du anbieten,
            ein kurzes virtuelles Gespräch zu führen. Das demonstriert nicht nur
            dein Interesse, sondern auch deine Kompetenz in der digitalen
            Kommunikation – eine Schlüsselqualifikation für Remote-Arbeit.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Die häufigsten Fehler bei Initiativbewerbungen vermeiden</H2>
        <p style={styles.content.paragraph}>
          Auch mit bester Vorbereitung können Fehler passieren, die deine
          Chancen auf Erfolg mindern. Hier sind die häufigsten Fallstricke und
          wie du sie vermeidest:
        </p>

        <H3>Mangelnde Recherche und fehlender Fokus</H3>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Zu allgemeine Bewerbung</strong>: Eine Initiativbewerbung,
            die für jedes Unternehmen passen könnte, überzeugt niemanden. Zeige,
            dass du dich spezifisch mit diesem Arbeitgeber auseinandergesetzt
            hast.
          </li>
          <li style={styles.content.listItem}>
            <strong>Unklare Positionierung</strong>: "Ich bin offen für alles"
            signalisiert Orientierungslosigkeit. Benenne konkret, welche
            Position du anstrebst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Falscher Ansprechpartner</strong>: Eine Bewerbung an die
            falsche Person wird oft nicht weitergeleitet. Investiere Zeit in die
            Identifikation des richtigen Entscheiders.
          </li>
        </ul>

        <H3>Probleme mit dem Homeoffice-Wunsch</H3>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Zu frühe Forderung</strong>: Wenn die Homeoffice-Option
            bereits in der Betreffzeile steht, kann das abschreckend wirken.
            Erwähne sie besser erst im Anschreiben und stelle vorher deinen
            Mehrwert dar.
          </li>
          <li style={styles.content.listItem}>
            <strong>Keine Begründung</strong>: Erkläre, warum Remote-Arbeit in
            deinem Fall sinnvoll ist und welche Vorteile sie für das Unternehmen
            bietet – nicht nur für dich persönlich.
          </li>
          <li style={styles.content.listItem}>
            <strong>Fehlende Kompromissbereitschaft</strong>: Absolute
            Forderungen nach 100% Homeoffice können Türen schließen.
            Signalisiere Flexibilität, wenn möglich.
          </li>
        </ul>

        <H3>Formale und inhaltliche Schwächen</H3>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Rechtschreib- und Grammatikfehler</strong>: Bei einer
            Initiativbewerbung, die unaufgefordert eingeht, wirken solche Fehler
            noch gravierender.
          </li>
          <li style={styles.content.listItem}>
            <strong>Standardformulierungen und Floskeln</strong>: Vermeide
            abgedroschene Phrasen wie "Hiermit bewerbe ich mich initiativ..."
            oder "Ich bin teamfähig und belastbar".
          </li>
          <li style={styles.content.listItem}>
            <strong>Zu langes Anschreiben</strong>: Gerade bei
            Initiativbewerbungen ist Prägnanz wichtig. Eine Seite sollte
            ausreichen, um zu überzeugen.
          </li>
          <li style={styles.content.listItem}>
            <strong>Fehlender Mehrwert</strong>: Wenn du nicht klar machst,
            welchen konkreten Nutzen du bringst, gibt es keinen Grund, dich
            einzustellen.
          </li>
        </ul>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Achte besonders auf deine digitale Präsenz: Vor allem wenn du dich
            für eine Homeoffice-Position bewirbst, werden
            Personalverantwortliche dein LinkedIn- oder XING-Profil und andere
            Online-Auftritte prüfen. Stelle sicher, dass diese professionell
            sind und mit deinen Bewerbungsunterlagen übereinstimmen.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Erfolgsbeispiele: Gelungene Initiativbewerbungen</H2>
        <p style={styles.content.paragraph}>
          Um besser zu verstehen, wie erfolgreiche Initiativbewerbungen
          aussehen, hier einige Beispiele aus der Praxis – mit besonderem Fokus
          auf Homeoffice-Positionen:
        </p>

        <H3>Fallbeispiel 1: Der relevante Problemlöser</H3>
        <p style={styles.content.paragraph}>
          Ein Marketing-Spezialist recherchierte, dass ein mittelständisches
          E-Commerce-Unternehmen zwar wuchs, aber kaum Social-Media-Präsenz
          hatte. In seiner Initiativbewerbung:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            Analysierte er die aktuelle Social-Media-Strategie des Unternehmens
            und identifizierte konkrete Verbesserungsmöglichkeiten
          </li>
          <li style={styles.content.listItem}>
            Präsentierte er einen knappen 30-Tage-Plan für die ersten Schritte
          </li>
          <li style={styles.content.listItem}>
            Verwies er auf ähnliche Erfolge bei früheren Arbeitgebern mit
            konkreten Zahlen
          </li>
          <li style={styles.content.listItem}>
            Erklärte er, wie er diese Aufgaben vollständig remote erledigen
            könnte und welche Tools er dafür nutzen würde
          </li>
        </ul>
        <p style={styles.content.paragraph}>
          Das Ergebnis: Er wurde zu einem Gespräch eingeladen und erhielt ein
          Angebot für eine neu geschaffene Position als Remote
          Social-Media-Manager.
        </p>

        <H3>Fallbeispiel 2: Die Netzwerknutzerin</H3>
        <p style={styles.content.paragraph}>
          Eine IT-Projektmanagerin wollte in ein bestimmtes Unternehmen
          wechseln, das keine offenen Stellen ausgeschrieben hatte:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            Sie baute zunächst Kontakte zu Mitarbeitern des Unternehmens über
            LinkedIn auf und führte informelle Gespräche
          </li>
          <li style={styles.content.listItem}>
            Durch diese Kontakte erfuhr sie von internen Herausforderungen bei
            der Implementierung einer neuen Software
          </li>
          <li style={styles.content.listItem}>
            In ihrer Initiativbewerbung bezog sie sich auf diese spezifische
            Herausforderung und ihre Erfahrung mit ähnlichen Projekten
          </li>
          <li style={styles.content.listItem}>
            Sie betonte ihre Erfahrung in der Leitung verteilter Teams und ihre
            Flexibilität, zwischen Remote-Arbeit und Vor-Ort-Präsenz zu wechseln
          </li>
        </ul>
        <p style={styles.content.paragraph}>
          Das Ergebnis: Sie wurde zunächst als Beraterin auf Projektbasis
          engagiert und später fest angestellt, mit drei Tagen Homeoffice pro
          Woche.
        </p>

        <H3>Fallbeispiel 3: Der Branchenwechsler mit Mehrwert</H3>
        <p style={styles.content.paragraph}>
          Ein Lehrer wollte in den Bereich E-Learning wechseln und bewarb sich
          initiativ bei einem EdTech-Startup:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            Er analysierte die bestehenden Lernmaterialien des Unternehmens und
            identifizierte pädagogische Verbesserungsmöglichkeiten
          </li>
          <li style={styles.content.listItem}>
            In seinem Anschreiben zeigte er, wie seine Unterrichtserfahrung
            einen einzigartigen Mehrwert für die Produktentwicklung bieten würde
          </li>
          <li style={styles.content.listItem}>
            Er betonte seine während der Pandemie erworbenen Fähigkeiten im
            digitalen Unterrichten und den Umgang mit verschiedenen
            Lernplattformen
          </li>
          <li style={styles.content.listItem}>
            Er hob hervor, dass er als Remote-Mitarbeiter flexibel für Tests mit
            Schülern und Lehrern bundesweit zur Verfügung stehen könnte
          </li>
        </ul>
        <p style={styles.content.paragraph}>
          Das Ergebnis: Das Startup schuf eine neue Position als "Pädagogischer
          Berater" im vollständigen Homeoffice.
        </p>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Gemeinsamer Erfolgsfaktor aller Beispiele: Die Bewerber zeigten
            nicht nur ihre Qualifikationen, sondern identifizierten konkrete
            Probleme oder Chancen und präsentierten sich als Lösung. Zudem
            stellten sie das Homeoffice nicht als persönlichen Wunsch, sondern
            als strategischen Vorteil für das Unternehmen dar.
          </p>
        </div>
      </section>

      <section style={styles.content.section}>
        <H2>Fazit: Deine Strategie für erfolgreiche Initiativbewerbungen</H2>
        <p style={styles.content.paragraph}>
          Eine gut durchdachte Initiativbewerbung kann Türen öffnen, die durch
          reguläre Bewerbungsprozesse verschlossen bleiben. Besonders für
          Positionen mit Homeoffice-Option bietet dieser Weg Chancen, da viele
          Unternehmen zwar offen für Remote-Arbeit sind, aber nicht explizit
          dafür ausschreiben.
        </p>

        <p style={styles.content.paragraph}>
          Die wichtigsten Erfolgsfaktoren für deine Initiativbewerbung sind:
        </p>
        <ul style={styles.content.list}>
          <li style={styles.content.listItem}>
            <strong>Gründliche Recherche</strong>: Verstehe das Unternehmen,
            seine Herausforderungen und Kultur, bevor du dich bewirbst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Spezifischer Mehrwert</strong>: Zeige klar auf, welches
            Problem du lösen oder welchen Beitrag du leisten kannst.
          </li>
          <li style={styles.content.listItem}>
            <strong>Relevante Ansprache</strong>: Identifiziere den richtigen
            Entscheider und sprich ihn oder sie persönlich an.
          </li>
          <li style={styles.content.listItem}>
            <strong>Homeoffice strategisch positionieren</strong>: Stelle
            Remote-Arbeit als Vorteil für das Unternehmen dar, nicht nur als
            deinen persönlichen Wunsch.
          </li>
          <li style={styles.content.listItem}>
            <strong>Professionelles Nachfassen</strong>: Bleib am Ball mit einem
            freundlichen, aber selbstbewussten Follow-up.
          </li>
        </ul>

        <p style={styles.content.paragraph}>
          Denk daran: Eine Initiativbewerbung erfordert mehr Vorbereitung und
          Recherche als eine reguläre Bewerbung, bietet aber auch die Chance,
          aus der Masse herauszustechen und Positionen zu finden, die nie
          öffentlich ausgeschrieben werden. Gerade im wachsenden Bereich der
          Homeoffice-Jobs, wo geografische Grenzen an Bedeutung verlieren,
          können gut gemachte Initiativbewerbungen der Schlüssel zu deiner
          nächsten beruflichen Station sein.
        </p>

        <div style={styles.content.note}>
          <p style={styles.content.paragraph}>
            Mit dem{" "}
            <a style={linkStyles} href="https://bewerbungshelfer.net">
              Bewerbungshelfer
            </a>{" "}
            kannst du deine Initiativbewerbung professionell gestalten. Die KI
            hilft dir, die richtigen Schwerpunkte zu setzen und deine
            Qualifikationen überzeugend auf das Unternehmen abzustimmen – auch
            für Positionen mit Homeoffice-Option. Nutze die interaktive
            Chat-Funktion, um aus deinem Lebenslauf und deinen Erfahrungen eine
            maßgeschneiderte Bewerbung zu erstellen, die genau zeigt, warum du
            die ideale Ergänzung für das Team bist – ob vor Ort oder remote.
          </p>
        </div>
      </section>
    </>
  );
}
